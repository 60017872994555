<template>
  <v-container
    id="dashboard"
    v-scroll="onScroll"
    fluid
    tag="section"
  >
    <spinner v-if="isLoading" />
    <div>
      <div
        class="d-flex"
      >
        <v-col class="col-lg-4 col-md-6 col-sm-10">
          <form @submit.prevent="search">
            <v-text-field
              v-model="searchText"
              clearable
              label="Search by name, city or town"
              prepend-inner-icon="mdi-magnify"
              single-line
              maxlength="64"
              autocomplete="off"
            />
          </form>
        </v-col>
        <div class="ml-auto">
          <v-col>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  large
                  icon
                  color="blue"
                  v-on="on"
                  @click="refresh"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>
        </div>
      </div>
    </div>
    <v-row v-if="events.length === 0 && !isLoading">
      <v-col
        cols="12"
      >
        <v-card
          class="mx-auto grow"
        >
          <v-card-text>
            <p class="display-1 text--primary text-center">
              {{ eventMessage }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        v-for="(event, index) in events"
        :key="event.id"
        cols="12"
        lg="4"
      >
        <v-card
          class="mx-auto my-12"
          max-width="374"
        >
          <v-img
            aspect-ratio="2"
            :src="event.poster.url"
            :lazy-src="event.poster.url"
            placeholder="../../../assets/no-image-placeholder.png"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-img
                  aspect-ratio="2"
                  src="../../../assets/no-image-placeholder.png"
                />
              </v-row>
            </template>
          </v-img>

          <v-card-title>
            <div class="truncate">
              {{ event.name }}
            </div>
          </v-card-title>

          <v-card-text>
            <div class="my-4 subtitle-1 truncate">
              {{ event.venue.name }} &bull; {{ event.venue.city }}
            </div>
            <v-responsive
              :class="event.type === 'free' ? 'error' : 'success'"
              class="text-center lighten-2 font-weight-bold rounded-pill d-inline-flex align-center justify-center pa-2"
            >
              {{ event.type }}
            </v-responsive>
            <v-responsive
              v-if="event.isBlocked"
              class="ml-1 text-center error font-weight-bold rounded-pill d-inline-flex align-center justify-center pa-2"
            >
              blocked
            </v-responsive>
            <v-responsive
              v-if="event.isPrivate"
              class="ml-1 text-center info font-weight-bold rounded-pill d-inline-flex align-center justify-center pa-2"
            >
              private
            </v-responsive>
          </v-card-text>

          <v-divider class="mx-4" />
          <v-row
            align="center"
            class="mx-0"
          >
            <v-expansion-panels
              accordion
              flat
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-card-title class="ml-n4">
                    Reviewed
                    <v-icon
                      v-if="event.isReviewed"
                      color="success"
                      small
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      color="error"
                      small
                    >
                      mdi-close-circle
                    </v-icon>
                  </v-card-title>

                  <v-card-title>
                    Verified
                    <v-icon
                      v-if="event.isVerified"
                      color="success"
                      small
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      color="error"
                      small
                    >
                      mdi-close-circle
                    </v-icon>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div>
                    <v-row
                      class="my-2"
                      align="center"
                      justify="center"
                    >
                      <v-btn
                        color="indigo"
                        :outlined="!event.isReviewed"
                        @click="toggleIsReviewed(event.slug, event.isReviewed)"
                      >
                        {{ event.isReviewed ? 'Unreview' : 'Review' }}
                      </v-btn>

                      <v-btn
                        color="indigo"
                        class="ma-1"
                        :outlined="!event.isVerified"
                        @click="toggleIsVerified(event.slug, event.isVerified)"
                      >
                        {{ event.isVerified ? 'Unverify' : 'Verify' }}
                      </v-btn>

                      <v-btn
                        color="indigo"
                        :outlined="!event.isBlocked"
                        @click="toggleIsBlocked(event.slug, event.isBlocked)"
                      >
                        {{ event.isBlocked ? 'Unblock' : 'Block' }}
                      </v-btn>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
          <v-divider class="mx-4" />
          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon
                    class="mr-1"
                    small
                  >
                    mdi-clock-outline
                  </v-icon>
                  {{ formatDate(event.date) }}
                </v-list-item-title>
              </v-list-item-content>

              <v-row
                align="center"
                justify="end"
              >
                <v-dialog
                  v-model="dialog[index]"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="indigo"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="getTicketsInfo(event.slug)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-card :loading="isLoadingTicketInfo">
                    <v-card-title
                      class="indigo--text font-weight-bold text-wrap"
                    >
                      <h3>{{ event.name }}</h3>
                    </v-card-title>
                    <v-card-title class="font-weight-bold">
                      <h3>Ticket Revenue</h3>
                    </v-card-title>
                    <div
                      v-if="ticketInfo.length > 0 && !isLoadingTicketInfo"
                      class="row mb-1"
                    >
                      <div
                        v-for="(ticket, id) in ticketInfo"
                        :key="id"
                        class="col-12"
                      >
                        <h3 class="text-center mt-4">
                          {{ ticket.type }}
                        </h3>
                        <div class="card ticket-card">
                          <div class="card-body">
                            <div class="row text-center">
                              <div class="col">
                                <div class="col-12 border-bottom font-weight-medium">
                                  <span>Sold</span>
                                </div>
                                <div class="col-12">
                                  <span class="h3">{{ ticket.bought }}</span>
                                </div>
                              </div>
                              <div class="col">
                                <div class="col-12 border-bottom font-weight-medium">
                                  <span>Remaining</span>
                                </div>
                                <div class="col">
                                  <span class="h3">{{ ticket.remaining }}</span>
                                </div>
                              </div>
                              <div class="col">
                                <div class="col-12 border-bottom font-weight-medium">
                                  <span>Revenue</span>
                                </div>
                                <div class="col-12">
                                  <span class="h3" />
                                  <span class="h3 green--text text--darken-4 font-weight-bold">{{ formatter(ticket.revenue, event.currency) }}</span>
                                </div>
                              </div>
                            </div>
                            <v-divider class="mx-4" />
                          </div>
                        </div>
                      </div>
                      <v-card-title class="font-weight-bold green--text text--darken-4 text-uppercase">
                        Grand Total: {{ formatter(totalRevenue, event.currency) }}
                      </v-card-title>
                      <v-card-title class="font-weight-bold green--text text--darken-1 text-uppercase">
                        Client Payout: {{ formatter((totalRevenue * ((100 - payoutPercentage)/100)) - (totalRevenue > 1000 ? payoutAmount : 0), event.currency) }}
                      </v-card-title>
                    </div>
                    <v-skeleton-loader
                      v-else
                      class="mx-auto"
                      max-width="300"
                      type="article"
                    />
                    <v-divider class="mx-4" />

                    <v-card-title class="font-weight-bold">
                      <h3>Organiser Details </h3>
                    </v-card-title>
                    <v-card-subtitle class="mt-4">
                      <v-icon
                        color="teal darken-2"
                      >
                        mdi-account
                      </v-icon>
                      {{ event.author.firstName }} {{ event.author.lastName }}
                    </v-card-subtitle>
                    <v-card-subtitle>
                      <v-icon
                        color="teal darken-2"
                      >
                        mdi-email
                      </v-icon> {{ event.author.email }}
                    </v-card-subtitle>
                    <v-card-subtitle>
                      <v-icon
                        color="teal darken-2"
                      >
                        mdi-phone
                      </v-icon> {{ event.author.phone }}
                    </v-card-subtitle>

                    <v-divider class="mx-4" />
                    <v-card-title class="font-weight-bold">
                      <h3>Billing Info</h3>
                      <v-btn
                        color="indigo"
                        class="ma-1"
                        :outlined="!event.payoutMade"
                        @click="togglePayoutMade(event.slug, event.payoutMade)"
                      >
                        Payout {{ !event.payoutMade ? 'Unpaid' : 'Paid' }}
                      </v-btn>
                    </v-card-title>
                    <v-card-subtitle class="font-weight-bold mt-4">
                      <v-icon
                        color="teal darken-2"
                      >
                        mdi-cash
                      </v-icon>
                      M-PESA: {{ event.author.billingInfo && event.author.billingInfo.paybill }}
                    </v-card-subtitle>
                    <v-card-subtitle class="font-weight-bold">
                      <v-icon
                        color="teal darken-2"
                      >
                        mdi-bank
                      </v-icon> Account Number: {{ event.author.billingInfo && event.author.billingInfo.bankCode }} - {{ event.author.billingInfo && event.author.billingInfo.bankAccountNumber }}
                    </v-card-subtitle>
                    <v-card-subtitle class="font-weight-bold">
                      <v-icon
                        color="teal darken-2"
                      >
                        mdi-email
                      </v-icon> Paypal: {{ event.author.billingInfo && event.author.billingInfo.paypal }}
                    </v-card-subtitle>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="red"
                        text
                        @click="dialog = []"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
            </v-list-item>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        v-show="showFab"
        color="primary darken-2"
        dark
        fab
        fixed
        bottom
        right
        @click="$vuetify.goTo('#dashboard', {duration: 500, offset: 0})"
      >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-fab-transition>
    <template v-if="!isLoading">
      <div class="text-center">
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <v-pagination
              v-model="page"
              :disabled="events.length === 0"
              :length="pageCount"
              :total-visible="6"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-card-text
              v-if="total > 0"
              class="subtitle-1"
            >
              {{ `${page === 1 ? 1 : (page-1) * perPage + 1} - ${(page * perPage) > total ? total:(page * perPage)} of ${total}` }}
            </v-card-text>
            <v-card-text
              v-else
              class="subtitle-1"
            >
              0 of 0
            </v-card-text>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="perPage"
              type="number"
              :disabled="events.length === 0"
              :items="perPageOptions"
              label="Total visible"
              @change="page = 1"
            />
          </v-col>
        </v-row>
      </div>
    </template>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import moment from 'moment'
  import spinner from '@/components/layouts/Spinner'
  import { redirectMixin } from '@/mixins/redirectMixin'

  export default {
    name: 'DashboardDashboard',
    components: {
      spinner,
    },
    mixins: [redirectMixin],
    data () {
      return {
        page: 1,
        perPage: 30,
        pageCount: 1,
        dialog: [],
        offsetTop: 0,
        total: 0,
        perPageOptions: [30, 60, 120, 240],
        searchText: '',
        eventMessage: 'No events',
        payoutPercentage: process.env.VUE_APP_PAYOUT_PERCENTAGE,
        payoutAmount: process.env.VUE_APP_PAYOUT_AMOUNT,
      }
    },
    computed: {
      hasNextPage () {
        return this.metadata.page < this.metadata.pageCount
      },
      showFab () {
        return this.offsetTop > 60
      },
      ...mapState({
        currentUser: state => state.userData.currentUser,
        events: state => state.eventsData.events,
        event: state => state.eventsData.event,
        metadata: state => state.eventsData.metadata,
        isLoading: state => state.eventsData.isLoading,
        eventError: state => state.eventsData.eventError,
        country: state => state.userData.country,
        isLoadingTicketInfo: state => state.ticketsData.isLoading,
        ticketInfo: state => state.ticketsData.ticketInfo,
        totalRevenue: state => state.ticketsData.totalRevenue,
      }),
    },
    watch: {
      metadata: function () {
        if (this.metadata && this.metadata.pageCount) {
          this.pageCount = this.metadata.pageCount
          this.total = this.metadata.total
        }
      },
      page: function () {
        this.fetchEvents({ page: this.page, perPage: this.perPage })
      },
      perPage: function () {
        this.fetchEvents({ page: this.page, perPage: this.perPage })
      },
      event: function () {
        if (this.event) {
          this.fetchEvents({
            page: this.page,
            perPage: this.perPage,
          })
        }
      },
      eventError: function () {
        if (this.eventError && this.eventError.statusCode === 401) {
          const { email } = this.currentUser

          this.redirectToSignin(email, 'Kindly log in again, your session may have expired or you may have logged out from another device')
        }
      },
    },
    created () {
      this.fetchEvents({ page: this.page, perPage: this.perPage })
    },
    methods: {
      ...mapActions('eventsData', ['fetchEvents', 'reviewVerifyBlockEvent', 'searchEvents']),
      ...mapActions('ticketsData', ['getRemainingTickets']),
      formatDate (date) {
        return moment(date).fromNow()
      },
      toggleIsReviewed (slug, isReviewed) {
        const { token } = this.currentUser
        this.reviewVerifyBlockEvent({
          event: {
            isReviewed: !isReviewed,
          },
          slug,
          token,
        })
      },
      toggleIsVerified (slug, isVerified) {
        const { token } = this.currentUser
        this.reviewVerifyBlockEvent({
          event: {
            isVerified: !isVerified,
          },
          slug,
          token,
        })
      },
      toggleIsBlocked (slug, isBlocked) {
        const { token } = this.currentUser
        this.reviewVerifyBlockEvent({
          event: {
            isBlocked: !isBlocked,
          },
          slug,
          token,
        })
      },
      togglePayoutMade (slug, payoutMade) {
        const { token } = this.currentUser
        this.reviewVerifyBlockEvent({
          event: {
            payoutMade: !payoutMade,
          },
          slug,
          token,
        })
      },
      getTicketsInfo (slug) {
        this.getRemainingTickets({ slug })
      },
      onScroll () {
        this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
      },
      refresh () {
        this.fetchEvents({ page: this.page, perPage: this.perPage })
      },
      search () {
        if (this.searchText) {
          this.searchEvents({ page: this.page, perPage: this.perPage, city: this.searchText, name: this.searchText })
        } else {
          this.refresh()
        }
      },
      formatter (value, currency) {
        const formatter = new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: currency === 'None' ? 'KES' : currency,
          minimumFractionDigits: 2,
        })

        return formatter.format(value)
      },
    },
  }
</script>
<style scoped>
.truncate {
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;
  white-space: nowrap;
}
</style>
